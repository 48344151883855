import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import i18n from "i18n-js";


export default function LocalName(props) {
  const open = props.isOpen;
  const handleClose = props.setLocalName;
  const auth = props.auth;
  const deviceid = props.deviceid;
  const devicename = props.name;
  const lang = props.lang;
  const [isSubmit, setSubmit] = React.useState(false);
  const [text, setText] = React.useState('');

  const handleChange = (event) => {
    setText(event.target.value);
  };
  const [iserror,setError] = React.useState(false);
  const [errormsg,setErrormsg] = React.useState('error msg');
  
  const fetchLocalNames=async(id,name)=>{
    const data = { "localNames": [{ "deviceId": id, "localName": name }] }
    return fetch('https://emulator.tracker.alap-iot.net/devices/localNames',
    {
        method: 'POST',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+auth.toString()
        }),
        body: JSON.stringify(data)
    })
    .then(res => {
        if(res.status==204)
        {
            console.log(res.status);
            setText("");
            handleClose();
            props.update(true);
        }
        else
        {
            console.log('Error');
            setError(true);
            setErrormsg("response error not 204")
        }
    })
  } 
  const handleSubmit=()=>{
    setSubmit(!isSubmit);
    if(deviceid.length===7 && text!==""){
      fetchLocalNames(deviceid,text);
    }
  }
  const handleDelete=()=>{
    setSubmit(!isSubmit);
    if(deviceid.length===7){
      fetchLocalNames(deviceid,"");
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{i18n.t("devicename")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {i18n.t("inputname")}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            defaultValue={devicename}
            label={"Device Name"}
            type="text"
            //value={text}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
        <Button onClick={handleDelete} color="primary" >
            {i18n.t("reset")}
          </Button>
          <Button onClick={handleClose} color="primary">
            {i18n.t("cancel")}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {i18n.t("submit")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}