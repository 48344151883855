import React from 'react';
import { makeStyles } from '@material-ui/styles';
import DeviceType from './components/ALAPdevicetypecomponent';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const Map = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DeviceType />
    </div>
  );
};

export default Map;
