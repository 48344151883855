import React,{ useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

//2020-08-20
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AutorenewIcon from '@material-ui/icons/Autorenew';

//2020-08-24
import i18n from "i18n-js";
import StatusComponent from './ALAPstatuscomponent';
import MapComponent from './ALAPmapcomponent';
import {SampleConsumer} from './../../../LangContext';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const Base64 = require('js-base64').Base64;

const useStyles = makeStyles((theme) => ({
  root: {
    //padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    margin: theme.spacing(1),
    marginLeft: theme.spacing(4),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  nested: {
    paddingLeft: theme.spacing(1),
  },
  button:{
    margin: theme.spacing(3),
    marginLeft: theme.spacing(4),
  },
  datepicker: {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function SearchComponent() {
  const classes = useStyles();
  const [iserror,setError] = useState(false);
  const [errormsg,setErrormsg] = useState("");
  const [deviceid, setDeviceid] =useState("");
  const [statuses, setStatuses] = useState([]);
  const [locations, setLocation] = useState([]);
  const [trajectory, setTrajectory] = useState([]);
  const [auth,setAuth] = useState( localStorage.getItem('@alaptoken') || ''); 
  const [type, setType] = useState("");
  const [istype, setIsType] = useState(false);
  const [startDate, setStartDate] = React.useState(new Date(new Date().getTime()-7*86400000));
  const [isstartDate, setIsStartDate] = useState(false);
  const [endDate, setEndDate] = React.useState(new Date());
  const [isendDate, setIsEndDate] = useState(false);
  const [lang, setLanguage] = React.useState("en");
  const [open, setOpen] = React.useState(false);

  const fetchToken=async(device,option,from,to)=>{
    let id = 'alaptest';
    let pw = 'alapiotpro2020test';
    console.log(id+" : "+pw);
    return fetch('https://emulator.tracker.alap-iot.net/auth/token',
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Basic '+Base64.encode(id+':'+pw)
        })
    })
    .then(res => {
        if(res.status==200)
        {
            console.log('State200');
            return res.json();
        }
        else
        {
            console.log('Error');
            setError(true);
            setErrormsg('response error not 200');
        }
    })
    .then(res => {            
        if(typeof(res) !== "undefined" )
        {
            console.log('Token : '+res.accessToken);
            console.log('APP_TRACKER_ISLOGIN : '+String(new Date().getTime()));

            setAuth(res.accessToken); 
            if(option === "info")
            {
              fetchDeviceStatus(device,res.accessToken);
              fetchDeviceLocation(device,res.accessToken);
            }
            else if(option === "trajectory")
            {
              fetchDeviceTrajectory(device,res.accessToken,from,to);
            }
        }
        else
        {
            console.log('Error');
            setError(true);
            setErrormsg('group api server has an error');
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg(error);
    });
  }
  const fetchDeviceStatus= async(deviceid)=>{
    console.log('https://emulator.tracker.alap-iot.net/devices/statuses/'+deviceid.toUpperCase().slice(1,8));
    return fetch('https://emulator.tracker.alap-iot.net/devices/statuses/'+deviceid.toUpperCase().slice(1,8),
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+auth.toString()
        })
    })
    .then(res => {
        if(res.status==200)
        {
          console.log('fetchDeviceStatus status 200');
          return res.json();
        }
        else
        {
          setError(true);
          setOpen(false);
          setErrormsg('response error not 200');
        }
    })
    .then(res => {
        console.log(res)
        if(typeof(res) !== "undefined" && typeof(res.status) !== "undefined" )
        {
            console.log(res.status);
            setStatuses(res.status);
            setOpen(false);
        }
        else
        {
          setOpen(false);
          setError(true);
          setErrormsg('could not get the devices');
        }
    })
    .catch((error) => {
        console.error(error);
        setOpen(false);
        setError(true);
        setErrormsg('fetch error');
    });
  }
  const fetchDeviceLocation= async(deviceid,from,to)=>{
    console.log('https://emulator.tracker.alap-iot.net/devices/locations/'+deviceid.toUpperCase().slice(1,8));
    return fetch('https://emulator.tracker.alap-iot.net/devices/locations/'+deviceid.toUpperCase().slice(1,8),
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+auth.toString()
        })
    })
    .then(res => {
        if(res.status==200)
        {
          console.log('fetchDeviceLocation status 200');
          return res.json();
        }
        else
        {
          setError(true);
          setErrormsg('response error not 200');
        }
    })
    .then(res => {
        console.log(res)
        if(typeof(res) !== "undefined" && typeof(res.location) !== "undefined" )
        {
            console.log(res.location);
            setLocation(res.location);
        }
        else
        {
          setError(true);
          setErrormsg('could not get the devices');
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg('fetch error');
    });
  }
  const fetchDeviceTrajectory= async(deviceid,from,to)=>{
    console.log(`https://emulator.tracker.alap-iot.net/devices/locations/${deviceid.toUpperCase().slice(1,8)}?limit=100&from=${from}&to=${to}`);
    return fetch(`https://emulator.tracker.alap-iot.net/devices/locations/${deviceid.toUpperCase().slice(1,8)}?limit=100&from=${from}&to=${to}`,
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+auth.toString()
        })
    })
    .then(res => {
        if(res.status==200)
        {
          console.log('fetchDeviceLocation status 200');
          return res.json();
        }
        else
        {
          setError(true);
          setErrormsg('response error not 200');
        }
    })
    .then(res => {
        console.log(res)
        if(typeof(res) !== "undefined" && typeof(res.location) !== "undefined" )
        {
            console.log(res.location);
            setTrajectory(res.location);
            setOpen(false);
        }
        else
        {
          setError(true);
          setErrormsg('could not get the devices');
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg('fetch error');
    });
  }
  const handleInputText=(event) =>{
    console.log(event.target.value);
    setDeviceid(event.target.value);
  }
  const handleBTN =()=>{
    if(deviceid.length === 8) setIsType(true);
  }
  const handleType=(option)=>{
    setType(option);
    if(deviceid.length === 8 && option==="info")
    {
      if(option === "info")
      {
        setOpen(true);
        fetchDeviceStatus(deviceid);
        fetchDeviceLocation(deviceid);
      }
    }
  }
  const handleStartDateChange = (date) => {
    console.log(date);
    setStartDate(date);
    setIsStartDate(true);
  };
  const handleEndDateChange = (date) => {
    console.log(date);
    setEndDate(date);
    setIsEndDate(true);
  };
  const handleBTNTrajectory=()=>{
    let from = Math.round(startDate.getTime()/1000);
    let to = Math.round(endDate.getTime()/1000);
    fetchDeviceTrajectory(deviceid,from,to);
    setOpen(true);
    /*
    if(isstartDate && isendDate){
      setIsStartDate(false);
      setIsEndDate(false);
      let from = Math.round(startDate.getTime()/1000);
      let to = Math.round(endDate.getTime()/1000);
      fetchDeviceTrajectory(deviceid,from,to);
    }
    else{
      setError(true);
      setErrormsg("Select start and end time both")
    }
    */
  }
  const handleClose = () => {
    setError(false);
    setErrormsg("");
    setOpen(false);
  };
  const getTableData=()=>{
    setType(false);
    setIsType(false);
    setStatuses([]);
    setLocation([]);
    setTrajectory([]);
    setIsStartDate(false);
    setIsEndDate(false);
    setErrormsg("");
    setError(false);
  }
  return (
    <Paper>
      <SampleConsumer>
      {
        (sample) => setLanguage(sample.state.value) 
      }
      </SampleConsumer>
      {/*<Typography variant="h4">{i18n.t("inputtext")}</Typography><br/>*/}
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        startIcon={<AutorenewIcon />}
        onClick={getTableData}
      >
        {i18n.t("reset")}
      </Button><br/>
      <Paper className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder={i18n.t("inputtext")}
          inputProps={{ 'aria-label': 'search device id' }}
          value={deviceid}
          onChange={handleInputText}
        />
        <IconButton className={classes.iconButton} aria-label="search" onClick={()=>handleBTN()}>
          <SearchIcon />
        </IconButton>
      </Paper><br/>
      {
        istype ?
        <Grid>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<InfoIcon />}
          onClick={()=>handleType("info")}
        >
          {i18n.t("latestinfo")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<SwapCallsIcon />}
          onClick={()=>handleType("trajectory")}
        >
          {i18n.t("trajectory")}
        </Button>
        </Grid>
        : null
      }
      <Grid>
        {
          type === "info" 
          ? statuses.length > 0 && locations.length > 0 
          && <StatusComponent lang={lang} deviceid={deviceid} status={statuses} location={locations} />
          : null
        }
      </Grid>
      {
        type === "trajectory" 
        ? <Paper component="form" className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={classes.datepicker}
              variant="normal"
              format="MM/dd/yyyy"
              margin="normal"
              id="start-date-picker-inline"
              label={i18n.t("starttime")}
              value={startDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              className={classes.datepicker}
              margin="normal"
              id="end-date-picker-dialog"
              label={i18n.t("endtime")}
              format="MM/dd/yyyy"
              value={endDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
        </MuiPickersUtilsProvider>
        </Paper>
        : null
      }
      {
        type === "trajectory" 
        ?<Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<SwapCallsIcon />}
          onClick={()=>handleBTNTrajectory()}
        >
          {i18n.t("trajectory")}
        </Button>
        : null
      }
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={iserror} autoHideDuration={3000} onClose={handleClose}>
        <MuiAlert onClose={handleClose} severity="warning">
          {errormsg}
        </MuiAlert>
      </Snackbar>
      {
        type==="trajectory" && trajectory.length>0
        ? <MapComponent deviceid={deviceid} lat={trajectory[0].lat} lng={trajectory[0].lng} trajectory={trajectory} />
        : null
      }
    </Paper>
  );
}