import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

//2020-08-03
import DevicesIcon from '@material-ui/icons/Devices';
import InfoIcon from '@material-ui/icons/Info';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MessageIcon from '@material-ui/icons/Message';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import TitleIcon from '@material-ui/icons/Title';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { async } from 'validate.js';

//2020-08-24
import i18n from "i18n-js";
const langjson=require('./../../../language.json');

const GOOGLEKEY='AIzaSyA5Ssic_6wLrGzQu6FfDArqZqku2UyHcEc';
const TOKEN = "76f34f0bd73d3f";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function NestedList(props) {
  const classes = useStyles();
  const statuses = props.status;
  const locations = props.location;
  const deviceid = props.deviceid;
  const [statusopen, setStatusOpen] = React.useState(false);
  const [locationopen, setLocationOpen] = React.useState(false);
  const [error,setError] = React.useState(false);
  const [errmsg,setErrormsg] = React.useState('error msg');
  const [address, setAddress] = React.useState("");

  const lang=props.lang;
  const [open, setOpen] = React.useState(false);

  const handleClick = (lat,lng) => {
    setAddress("");
    if(!open)fetchGeocodingAPI(lat,lng);
    setOpen(!open);
  };

  React.useEffect(() => {
    //i18n.translations = langjson;
    //i18n.locale = lang;
    //i18n.fallbacks = true;
  },[]);

  const fetchGeocodingAPI=async(lat,lng)=>{
    return fetch(`https://nominatim.openstreetmap.org/reverse?format=json&accept-language=${lang}&lat=${lat}&lon=${lng}&zoom=18&addressdetails=1`)
    .then(res => {
        if(res.status==200)
        {
          return res.json();
        }
        else
        {
          console.log('Error');
          setError(true);
          setErrormsg('response error not 200');
        }
    })
    .then(res => {
        if(typeof(res) !== "undefined" && typeof(res.display_name) !== "undefined" )
        {
            if(res.display_name !== null )
            {
                console.log(res.display_name);
                setAddress(res.display_name)
            }
            else
            {
              console.log('Error');
              setError(true);
              setErrormsg('response status NOK');
            }   
        }
        else
        {
          console.log('Error');
          setError(true);
          setErrormsg('response error getting the data');
        }
    })
    .catch((error) => {
      console.log('Error');
      setError(true);
      setErrormsg(error);
    })
  }  
  /*
  const fetchGeocodingAPI=async(lat,lng)=>{
    return fetch(`https://maps.googleapis.com/maps/api/geocode/json?language=${lang}&latlng=${lat},${lng}&key=${GOOGLEKEY}`)
    .then(res => {
        if(res.status==200)
        {
          return res.json();
        }
        else
        {
          console.log('Error');
          setError(true);
          setErrormsg('response error not 200');
        }
    })
    .then(res => {
        if(typeof(res) !== "undefined" && typeof(res.status) !== "undefined" )
        {
            if(res.status == 'OK')
            {
                console.log(res.results);
                res.results.map((item,index)=> index == 0 
                  && setAddress(item.formatted_address)
                )
            }
            else
            {
              console.log('Error');
              setError(true);
              setErrormsg('response status NOK');
            }   
        }
        else
        {
          console.log('Error');
          setError(true);
          setErrormsg('response error getting the data');
        }
    })
    .catch((error) => {
      console.log('Error');
      setError(true);
      setErrormsg(error);
    })
  }   
  */
  const handleClickStatus = () => {
    setStatusOpen(!statusopen);
  };
  const handleClickLocation = () => {
    setLocationOpen(!locationopen);
  };
  const convertTime=(data,option)=>{
    let date = new Date(parseInt(data));
    let res = '';
    if(option === 'DATE') res = date.toLocaleDateString();
    else if(option === 'DATETIME') res = date.toLocaleString();
    else res = date.toLocaleDateString();
    return res;
  }
  const convertBatt = (data)=>{
    if(data === 0 ) return "Limit";
    else if(data === 1 ) return "Average";
    else if(data === 2 ) return "Good";
    else if(data === 3 ) return "Excellent";
    else return "Error";
  }
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {i18n.t("latestinfo")}
        </ListSubheader>
      }
      className={classes.root}
    >
      <ListItem button>
        <ListItemIcon>
          <DevicesIcon />
        </ListItemIcon>
        <ListItemText primary={`${i18n.t("deviceid")} : ${deviceid}`} />
      </ListItem>
      <ListItem button onClick={handleClickStatus}>
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary={i18n.t("status")} />
        {statusopen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={statusopen} timeout="auto" unmountOnExit>
        {
        statuses.map((data,index)=>
            <List key={index} component="div" disablePadding>
            <ListItem button className={classes.nested}>
                <ListItemIcon>
                <MessageIcon />
                </ListItemIcon>
                <ListItemText primary={`${i18n.t("msgtype")} : ${data.type}`} />
            </ListItem>
            <ListItem button className={classes.nested}>
                <ListItemIcon>
                <FormatListNumberedIcon />
                </ListItemIcon>
                <ListItemText primary={`${i18n.t("seqnumber")} : ${data.seqNumber}`} />
            </ListItem>
            <ListItem button className={classes.nested}>
                <ListItemIcon>
                <QueryBuilderIcon />
                </ListItemIcon>
                <ListItemText primary={`${i18n.t("lastcom")} : ${convertTime(data.time*1000,'DATETIME')}`} />
            </ListItem>
            <ListItem button className={classes.nested}>
                <ListItemIcon>
                <SignalCellularAltIcon />
                </ListItemIcon>
                <ListItemText primary={`${i18n.t("lqi")} : ${data.lqi}`} />
            </ListItem>
            <ListItem button className={classes.nested}>
                <ListItemIcon>
                <BatteryFullIcon />
                </ListItemIcon>
                <ListItemText primary={`${i18n.t("battery")} : ${convertBatt(data.battery)}`} />
            </ListItem>
            <ListItem button className={classes.nested}>
                <ListItemIcon>
                <WbSunnyIcon />
                </ListItemIcon>
                <ListItemText primary={`${i18n.t("temperature")} : ${data.temperature}`} />
            </ListItem>
            <ListItem button className={classes.nested}>
                <ListItemIcon>
                <TimelapseIcon />
                </ListItemIcon>
                <ListItemText primary={`${i18n.t("timestamp")} : ${data.timestamp*6} min elapsed`} />
            </ListItem>
            <ListItem button className={classes.nested}>
                <ListItemIcon>
                <BookmarkBorderIcon />
                </ListItemIcon>
                <ListItemText primary={`${i18n.t("payload")} : ${data.payload}`} />
            </ListItem>
            </List>
            )
        }
      </Collapse>
      <ListItem button onClick={handleClickLocation}>
        <ListItemIcon>
          <MyLocationIcon />
        </ListItemIcon>
        <ListItemText primary={i18n.t("location")} />
        {locationopen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={locationopen} timeout="auto" unmountOnExit>
        {
            locations.map((data,index)=>
            <List key={index} component="div" disablePadding>
            <ListItem button className={classes.nested}>
                <ListItemIcon>
                <FormatListNumberedIcon />
                </ListItemIcon>
                <ListItemText primary={`${i18n.t("seqnumber")} : ${data.seqNumber}`} />
            </ListItem>
            <ListItem button className={classes.nested}>
                <ListItemIcon>
                <QueryBuilderIcon />
                </ListItemIcon>
                <ListItemText primary={`${i18n.t("lastcom")} : ${convertTime(data.time*1000,'DATETIME')}`} />
            </ListItem>
            <ListItem button className={classes.nested}>
                <ListItemIcon>
                <SignalCellularAltIcon />
                </ListItemIcon>
                <ListItemText primary={`${i18n.t("lqi")} : ${data.lqi}`} />
            </ListItem>
            <ListItem button className={classes.nested} onClick={()=>handleClick(data.lat,data.lng)}>
                <ListItemIcon>
                <LocationOnIcon />
                </ListItemIcon>
                <ListItemText primary={`${i18n.t("address")} : ${data.lat} , ${data.lng}`} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  {
                    address !== null
                    ? <ListItemText primary={`${i18n.t("address")} : ${address}`}  />
                    : <ListItemText primary={`${i18n.t("address")} : "no address"`}  />
                  }
                </ListItem>
              </List>
            </Collapse>
            </List>
            )
        }
      </Collapse>
    </List>
  );
}