import React,{useState, useEffect} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import Button from '@material-ui/core/Button';
import AutorenewIcon from '@material-ui/icons/Autorenew';

//2020-07-31
import SignalCellular1BarIcon from '@material-ui/icons/SignalCellular1Bar';
import SignalCellular2BarIcon from '@material-ui/icons/SignalCellular2Bar';
import SignalCellular3BarIcon from '@material-ui/icons/SignalCellular3Bar';
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import { green,blue,orange,red } from '@material-ui/core/colors';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

//2020-08-03
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import DetailTable from './ALAPdetailtable';

//2020-08-24
import i18n from "i18n-js";
import {SampleConsumer} from "./../../../LangContext";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

//2020-09-30
import LocalName from './ALAPlocalName';

//2020-10-02
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';


const Base64 = require('js-base64').Base64;

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function SimpleTable() {
  const classes = useStyles();
  const [groups, setGroups] = useState([]);
  const [devicetypes, setDevicetype] = useState([]);
  const [devices, setDevice] = useState([]);
  const [clickeddevice, setClickedDevice] = useState('');
  const [clickeddevicetype, setClickedDeviceType] = useState('');
  const [isdeviceLoading, setDeviceLoading] = useState(true);
  const [isdevicetypeLoading, setDeviceTypeLoading] = useState(true);
  const [iserror,setError] = useState(false);
  const [errormsg,setErrormsg] = useState('error msg');
  const [auth,setAuth] = useState( localStorage.getItem('@alaptoken') || ''); 
  const [locations, setLocations] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const [lang, setLanguage] = useState("en");
  const [open, setOpen] = React.useState(false);
  const [isName, setName]=React.useState(false);
  const [localdevice, setLocalDevice] = useState('');
  const [localdevicename, setLocalDeviceName] = useState('');

  const handleClose = (msg) => {
    setOpen(false);
    if(msg === false) {
      setError(true);
      setErrormsg("Can not get the latest information");
    }
  };
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  useEffect(() => {
    // Update the groups and devicetype data
    const res = fetchGroups();
  },[]);

  const createData=(id, name, state, seqnum, lqi, lastcom, createtime, activatetime)=> {
    return { id, name, state, seqnum, lqi, lastcom, createtime, activatetime };
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const fetchToken=async()=>{
    let id = 'alaptest';
    let pw = 'alapiotpro2020test';
    console.log(id+" : "+pw);
    return fetch('https://emulator.tracker.alap-iot.net/auth/token',
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Basic '+Base64.encode(id+':'+pw)
        })
    })
    .then(res => {
        if(res.status==200)
        {
            console.log('State200');
            return res.json();
        }
        else
        {
            console.log('Error');
            setError(true);
            setErrormsg('response error not 200');
        }
    })
    .then(res => {            
        if(typeof(res) !== "undefined" )
        {
            console.log('Token : '+res.accessToken);
            console.log('APP_TRACKER_ISLOGIN : '+String(new Date().getTime()));

            setAuth(res.accessToken); 
            fetchGroups(res.accessToken); 
        }
        else
        {
            console.log('Error');
            setError(true);
            setErrormsg('group api server has an error');
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg(error);
    });
  }
  const fetchGroups=async()=>{
    //console.log('Auth : ' + auth.toString());
    return fetch('https://emulator.tracker.alap-iot.net/groups?subGroup=true',
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+auth.toString()
        })
    })
    .then(res => {
        if(res.status==200)
        {
            console.log('Success');
            return res.json();
        }
        else
        {
            console.log('Status Error : '+res.status);
            setError(true);
            setErrormsg('response error not 200');
        }
    })
    .then(res => {            
        if(typeof(res) !== "undefined" && typeof(res.groups) !== 'undefined')
        {
            console.log(res.groups);
            setGroups(res.groups);
        }
        else
        {
            console.log('undefined Error');
            setError(true);
            setErrormsg('group api has error');
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg('fetch error');
    });
  }
  const fetchDeviceType=async(groupid)=>{
    console.log('Groupdid : '+groupid);
    return fetch('https://emulator.tracker.alap-iot.net/device-types?groupId='+groupid,
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+auth.toString()
        })
    })
    .then(res => {
        if(res.status==200)
        {
          console.log('devicetype ok');
          return res.json();
        }
        else
        {
          setError(true);
          setErrormsg('response error not 200');
        }
    })
    .then(res => {
        if(typeof(res) !== "undefined" && typeof(res["device-types"]) !== "undefined" )
        {
          console.log(res["device-types"]);
          setDevicetype(res["device-types"]);
          setDeviceTypeLoading(false);
        }
        else
        {
          setError(true);
          setErrormsg('could not get the devicetypes');
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg('fetch error');
    });
  }
  const fetchDevice=async(devicetypeid)=>{
    return fetch('https://emulator.tracker.alap-iot.net/devices?deviceTypeId='+devicetypeid,
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+auth.toString()
        })
    })
    .then(res => {
        if(res.status==200)
        {
          console.log('device ok');
          return res.json();
        }
        else
        {
          setError(true);
          setErrormsg('response error not 200');
        }
    })
    .then(res => {
        if(typeof(res) !== "undefined" && typeof(res.devices) !== "undefined" )
        {
            console.log(res.devices);
            let rawdata = [];
            res.devices.map((device,index)=>
              rawdata.push(createData(device.id,device.localName,device.state,device.sequenceNumber,device.lqi,device.lastCom,device.creationTime,device.activationTime))
            );
            setRows(rawdata);
            console.log(rawdata);
            setDeviceLoading(false);
        }
        else
        {
          setError(true);
          setErrormsg('could not get the devices');
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg('fetch error');
    });
  }
  
  const getTableData=()=>{
    setRows([]);
    setGroups([]);
    setDevicetype([]);
    setDevice([]);
    setClickedDevice("");
    const res = fetchGroups();
  }
  const handleClickGroup=(event)=>{
    setDevicetype([]);
    let groupid = event.target.value;
    console.log(groupid);
    if(groupid.length === 24)
    {
        fetchDeviceType(groupid);
    }
  }
  const handleClickDeviceType=(event)=>{
    let devicetypeid = event.target.value;
    setClickedDeviceType(devicetypeid);
    console.log(devicetypeid);
    if(devicetypeid.length === 24)
    {
        fetchDevice(devicetypeid);
    }
  }
  const handleUpdateDevice=(isupdate)=>{
    if(isupdate){
        fetchDevice(clickeddevicetype);
    }
  }
  const convertDetailTable=(deviceid)=>{
    console.log("convertDetailTable");
    setOpen(true);
    //setClickedDevice("");
    setClickedDevice(deviceid);
  }
  const setLocalName=(deviceid,devicename)=>{
    console.log("setLocalName");
    setLocalDevice(deviceid);
    setLocalDeviceName(devicename);
    setName(!isName);
  }
  const convertTime=(data,option)=>{
    let date = new Date(parseInt(data));
    let res = '';
    if(option === 'DATE') res = date.toLocaleDateString('ja-JP');
    else if(option === 'DATETIME') res = date.toLocaleString('ja-JP');
    else res = date.toLocaleDateString('ja-JP');
    return res;
  }
  const convertState = (data)=>{
    /*
    if(data === 0 ) return BlockIcon;
    else if(data === 1 ) return "DEAD";
    else if(data === 2 ) return "OFF_CONTRACKT";
    else if(data === 3 ) return "DISABLED";
    else if(data === 4 ) return "WARN";
    else if(data === 5 ) return "DELETED";
    else if(data === 6 ) return "SUSPENDED";
    else if(data === 7 ) return "NOT_ACTIVABLE";
    else return "ERROR";
    */
    if( data === 0 ) return <CheckCircleIcon style={{ color: green[500] }}/>;
    else return <BlockIcon style={{ color: red[500] }}/>;
  }
  const convertLQI = (data)=>{
    if(data === 0 ) return <SignalCellular1BarIcon style={{ color: red[500] }}/>;
    else if(data === 1 ) return <SignalCellular2BarIcon style={{ color: orange[500] }}/>;
    else if(data === 2 ) return <SignalCellular3BarIcon style={{ color: green[500] }}/>;
    else if(data === 3 ) return <SignalCellular4BarIcon style={{ color: blue[500] }}/>;
    else return <SignalCellular1BarIcon style={{ color: red[500] }}/>;
  }
  const handleError = () => {
    setError(false);
    setErrormsg("");
  };
  return (
    <Paper>
    <SampleConsumer>
    {
      (sample) => setLanguage(sample.state.value)
    }
    </SampleConsumer>
    <Snackbar open={iserror} autoHideDuration={3000} onClose={handleError}>
      <MuiAlert onClose={handleError} severity="warning">
        {errormsg}
      </MuiAlert>
    </Snackbar>
    <Button
      variant="contained"
      color="secondary"
      className={classes.button}
      startIcon={<AutorenewIcon />}
      onClick={getTableData}
    >
      {i18n.t("reset")}
    </Button><br/>
    <FormControl className={classes.formControl} error>
      <InputLabel htmlFor="name-native-error">{i18n.t("group")}</InputLabel>
      <NativeSelect onChange={handleClickGroup}>
        <option aria-label="None" value="" />
        {
        groups.map((group,index)=><option key={index} value={group.id}>{group.name}</option>)
        }
      </NativeSelect>
    </FormControl>
    <FormControl className={classes.formControl} error>
      <InputLabel htmlFor="name-native-error">{i18n.t("devicetype")}</InputLabel>
      <NativeSelect onChange={handleClickDeviceType}>
        <option aria-label="None" value="" />
        {
        devicetypes.map((devicetype,index)=><option key={index} value={devicetype.id}>{devicetype.name}</option>)
        }
      </NativeSelect>
    </FormControl>
    <TableContainer component={Paper}>
    {
        rows.length >0 
        ?<Table className={classes.table} >
        <TableHead>
            <TableRow >
            <TableCell >{i18n.t("deviceid")}</TableCell>
            <TableCell align="right">{i18n.t("devicename")}</TableCell>
            <TableCell align="right">{i18n.t("state")}</TableCell>
            <TableCell align="right">{i18n.t("lqi")}</TableCell>
            <TableCell align="right">{i18n.t("seqnumber")}</TableCell>
            <TableCell align="right">{i18n.t("lastcom")}</TableCell>
            <TableCell align="right">{i18n.t("activationtime")}</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
        {
        rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
        return (
            <TableRow key={row.id}>
            <TableCell component="th" scope="row" >
                <Button　variant="contained" color="secondary" onClick={()=>convertDetailTable(row.id)}>{row.id}</Button>
            </TableCell>
            <TableCell component="th" scope="row" align="right">
              {
                row.name !== "" 
                ? <Button　variant="contained" color="secondary" onClick={()=>setLocalName(row.id,row.name)}>{row.name.slice(0,10)}</Button>
                : <IconButton color="secondary" onClick={()=>setLocalName(row.id,'')} ><AddCircleIcon /></IconButton>
              }
            </TableCell>
            <TableCell component="th" scope="row" align="right">{convertState(row.state)}</TableCell>
            <TableCell component="th" scope="row" align="right">{convertLQI(row.lqi)}</TableCell>
            <TableCell component="th" scope="row" align="right">{row.seqnum}</TableCell>
            <TableCell component="th" scope="row" align="right">{convertTime(row.lastcom,'DATETIME')}</TableCell>
            <TableCell component="th" scope="row" align="right">{convertTime(row.activatetime,'DATE')}</TableCell>
            </TableRow>
        );
        })
        }
        </TableBody>
        </Table>
        : null
    }
    </TableContainer>
    <TablePagination
        rowsPerPageOptions={[5, 10, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
    />
    <LocalName lang={lang} isOpen={isName} setLocalName={setLocalName} auth={auth} deviceid={localdevice} name={localdevicename} update={handleUpdateDevice}/>
    <Backdrop className={classes.backdrop} open={open} onClick={handleClose} >
      <CircularProgress color="inherit" />
    </Backdrop>
    {
        clickeddevice !== ""
        ? <DetailTable auth={auth} deviceid={clickeddevice} lang={lang} handleClose={handleClose}/>
        : null
    }
    </Paper>
  );
}