import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { number } from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      //maxWidth: 500,
      padding: theme.spacing(4),
      backgroundColor: theme.palette.background.paper,
    },
    msg : {
      margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
}));
export default function Setting() {
  const classes = useStyles();
  const [alivestaticstatetimer, setAlivestaticstatetimer] = React.useState(8);
  const [downlinkfrequency, setDownlinkfrequency] = React.useState(14);
  const [speed, setSpeed] = React.useState(8);
  const [broadcastcap, setBroadcastcap] = React.useState(6);
  const [movingtostaticstatetimer, setMovingtostaticstatetimer] = React.useState(0);
  const [distance, setDistance] = React.useState(4);
  const [alivemovingstatetimer, setAlivemovingstatetimer] = React.useState(0);
  const [stopdetectionwindow, setStopdetectionwindow] = React.useState(1);
  const [stopdetectionthreshold, setStopdetectionthreshold] = React.useState(1);

  const handleAlivestaticstatetimer = (event) => {
    setAlivestaticstatetimer(event.target.value);
  };
  const handleDownlinkfrequency= (event) => {
    setDownlinkfrequency(event.target.value);
  };
  const handleSpeed = (event) => {
    setSpeed(event.target.value);
  };
  const handleDistance= (event) => {
    setDistance(event.target.value);
  };
  const handleBroadcastcap= (event) => {
    setBroadcastcap(event.target.value);
  };
  const handleMovingtostaticstatetimer= (event) => {
    setMovingtostaticstatetimer(event.target.value);
  };
  const handleAlivemovingstatetimer = (event) => {
    setAlivemovingstatetimer(event.target.value);
  };
  const handleStopdetectionthreshold = (event) => {
    setStopdetectionthreshold(event.target.value);
  };
  const handleStopdetectionwindow = (event) => {
    setStopdetectionwindow(event.target.value);
  };
  const alivestaticstatetimerarr = [];
  for (let i = 0; i < 256; i++) {
    alivestaticstatetimerarr.push(<MenuItem value={i}>{i*3} hrs</MenuItem>)
  }
  const downlinkfrequencyarr = [];
  downlinkfrequencyarr.push(<MenuItem value={0}>6 hrs</MenuItem>)
  for (let i = 1; i < 256; i++) {
    downlinkfrequencyarr.push(<MenuItem value={i}>{i} days</MenuItem>)
  }
  const broadcastcaparr = [];
  broadcastcaparr.push(<MenuItem value={0}>No limit</MenuItem>)
  broadcastcaparr.push(<MenuItem value={1}>Max 1 time/day</MenuItem>)
  for (let i = 2; i < 16; i++) {
    broadcastcaparr.push(<MenuItem value={i}>Max {i*3-3} times/day</MenuItem>)
  }
  const speedarr = [];
  for (let i = 0; i < 16; i++) {
    speedarr.push(<MenuItem value={i}>{i*5} km/h</MenuItem>)
  }
  const distancearr = [];
  for (let i = 0; i < 16; i++) {
    distancearr.push(<MenuItem value={i}>{i*0.5} km</MenuItem>)
  }
  const movingtostaticstatetimerarr = [];
  movingtostaticstatetimerarr.push(<MenuItem value={0}>10min</MenuItem>)
  for (let i = 1; i < 16; i++) {
    movingtostaticstatetimerarr.push(<MenuItem value={i}>{i*0.5} hrs</MenuItem>)
  }
  const alivemovingstatetimerarr = [];
  alivemovingstatetimerarr.push(<MenuItem value={0}>OFF</MenuItem>)
  for (let i = 1; i < 16; i++) {
    alivemovingstatetimerarr.push(<MenuItem value={i}>{i} hrs</MenuItem>)
  }
  const stopdetectionthresholdarr = [];
  stopdetectionthresholdarr.push(<MenuItem value={0}>Lower</MenuItem>)
  stopdetectionthresholdarr.push(<MenuItem value={1}>Normal</MenuItem>)
  stopdetectionthresholdarr.push(<MenuItem value={2}>Higher</MenuItem>)
  stopdetectionthresholdarr.push(<MenuItem value={3}>Highest</MenuItem>)

  const stopdetectionwindowarr = [];
  stopdetectionwindowarr.push(<MenuItem value={0}>Shorter</MenuItem>)
  stopdetectionwindowarr.push(<MenuItem value={1}>Normal</MenuItem>)
  stopdetectionwindowarr.push(<MenuItem value={2}>Longer</MenuItem>)
  stopdetectionwindowarr.push(<MenuItem value={3}>Longest</MenuItem>)

  const pad2=(num)=>{
    return (parseInt(num,16) < 16 ? '0' : '') + num;
  }
  const getdata=()=>{
    let downlink8bytes ="";
    let byte0 = "0x50";
    let byte1 = pad2(alivestaticstatetimer.toString(16)).toUpperCase();
    let byte2 = pad2(downlinkfrequency.toString(16)).toUpperCase();
    let byte3 = speed.toString(16).toUpperCase()+broadcastcap.toString(16).toUpperCase();
    let byte4 = movingtostaticstatetimer.toString(16).toUpperCase()+distance.toString(16).toUpperCase();
    let byte5 = (stopdetectionwindow<<2|stopdetectionthreshold).toString(16).toUpperCase()+alivemovingstatetimer.toString(16).toUpperCase();
    downlink8bytes=byte0+byte1+byte2+byte3+byte4+byte5+"0010";
    return "Downlink 8 bytes : "+downlink8bytes;
  }

  return (
    <div className={classes.root}>
        <Typography color="primary" variant="h4">{getdata()}</Typography>
        <br/>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Alive Static State timer</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={alivestaticstatetimer}
            onChange={handleAlivestaticstatetimer}
          >
          {alivestaticstatetimerarr}
          </Select>
        </FormControl><br/>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Downlink frequency</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={downlinkfrequency}
            onChange={handleDownlinkfrequency}
          >
          {downlinkfrequencyarr}
          </Select>
        </FormControl><br/>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Broadcastcap</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={broadcastcap}
            onChange={handleBroadcastcap}
          >
          {broadcastcaparr}
          </Select>
        </FormControl><br/>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Speed</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={speed}
            onChange={handleSpeed}
          >
          {speedarr}
          </Select>
        </FormControl><br/>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Distance</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={distance}
            onChange={handleDistance}
          >
          {distancearr}
          </Select>
        </FormControl><br/>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Moving to Static State Timer</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={movingtostaticstatetimer}
            onChange={handleMovingtostaticstatetimer}
          >
          {movingtostaticstatetimerarr}
          </Select>
        </FormControl><br/>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Alive Moving state timer</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={alivemovingstatetimer}
            onChange={handleAlivemovingstatetimer}
          >
          {alivemovingstatetimerarr}
          </Select>
        </FormControl><br/>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Stop detection threshold</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={stopdetectionthreshold}
            onChange={handleStopdetectionthreshold}
          >
          {stopdetectionthresholdarr}
          </Select>
        </FormControl><br/>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Stop detection window</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={stopdetectionwindow}
            onChange={handleStopdetectionwindow}
          >
          {stopdetectionwindowarr}
          </Select>
        </FormControl>
    </div>
  );
}