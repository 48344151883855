import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import { green, pink } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60,
    color: '#fff',
    backgroundColor: green[500],
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;
  const group = props.group;
  const username = props.username;

  const classes = useStyles();

  const user = {
    //name: 'AlpsAlpine IoT S/P',
    username : typeof(username)!=="undefined" ? username : "test",
    group : group.length>0 ? group[0].name : "test",
    id : group.length>0 ?  group[0].id : "test",
    timezone : group.length>0 ?  group[0].timezone : "test",
    avatar: '/images/avatars/alap.png',
    bio: 'Admin account'
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        //alt="Person"
        className={classes.avatar}
        //component={RouterLink}
        //src={user.avatar}
        //to="/settings"
      >{user.username.slice(0,2)}</Avatar>
      <Typography
        className={classes.name}
        variant="h4"
      >
        {user.username}
      </Typography>
      {/*<Typography variant="body2">{user.timezone}</Typography>*/}
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
