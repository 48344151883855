import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';


var bin="";
var hexdata="";
var msgtype='';
var type='';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    //maxWidth: 500,
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
  },
  input:{
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function hex2bin(hex){
  bin='';
  hexdata=hex;
  for(var i=0; i <hex.length; i=i+2)
  {
    bin +=("00000000" + parseInt(hex.slice(i,2+i), 16).toString(2)).substr(-8);
  }
  return <div><Typography variant="h5" gutterBottom>Binary data : {bin}</Typography></div>;
}
function packetType(bin){
  type='error';
  if(bin.length===64) type='downlink';
  else if(bin.length>0 && bin.length<88) type='Uplink : Status message';
  else if(bin.length === 96) type='Uplink : AP message';
  return <div><Typography variant="h5" gutterBottom>PacketType : {type}</Typography></div>;
}
function batteryType(bin){
  let batt = bin.slice(0,2);
  let batttype='error';
  if(batt==='11') batttype='Excellent : 90%';
  else if(batt==='10') batttype='Good : 60%';
  else if(batt==='01') batttype='OK : 30%';
  else if(batt==='00') batttype='Almost Empty : 10%';
  
  return <div><Typography variant="h5" gutterBottom>Battery : {batttype}</Typography></div>;
}
function tempType(bin){
  let temp = 0;
  temp = parseInt(bin.slice(8,16),2)/2-40;
  return <div><Typography variant="h5" gutterBottom>Temperature : {temp}</Typography></div>;
}
function timestampType(bin){
  let timestamp = 0;
  timestamp = parseInt(bin.slice(16,32),2);
  return <div><Typography variant="h5" gutterBottom>Timestamp : {timestamp}~{timestamp*6} minutes passed</Typography></div>;
}
function messageType(bin,type){
  msgtype='error';
  let lsb=bin.slice(2,5);
	let AckSeqNr =bin.slice(5,7);
  let msb=bin.slice(7,8);
  
  if(type === 'Uplink : Status message')
  {
    let msgtypevalue=parseInt(msb,2)*8+parseInt(lsb,2);
    switch(msgtypevalue){
      case 0 :
        if(bin.length===24)
        {
        msgtype="Normal status message";
        return (
          <div>
            <Typography variant="h5" gutterBottom>MessageType(0) : Normal status message</Typography>
            {batteryType(bin)}
            {tempType(bin)}
            {timestampType(bin)}
          </div>
        );
        }
        break;
      case 1 : 
        if(bin.length===16)
        {
        msgtype="Activation";
        return (
          <div>
            <Typography variant="h5" gutterBottom>MessageType(1) : Activation</Typography>
            {batteryType(bin)}
            {tempType(bin)}
          </div>
        );
        }
        break;
      case 2 : 
        if(bin.length===16)
        {
        msgtype="Deactivation";
        return (
          <div>
            <Typography variant="h5" gutterBottom>MessageType(2) : Deactivation</Typography>
            {batteryType(bin)}
          </div>
        );
        }
        break;
      case 3 : 
        if(bin.length===16)
        {
        msgtype="Diagnostic message(Periodic)";
        return (
          <div>
            <Typography variant="h5" gutterBottom>MessageType(3) : Diagnostic message(Periodic)</Typography>
          </div>
        );
        }
        break;
      case 4 : 
        if(bin.length===16)
        {
        msgtype="Text message";
        return (
          <div>
            <Typography variant="h5" gutterBottom>MessageType(4) : Text message</Typography>
          </div>
        );
        }
        break;
      case 5 : 
        if(bin.length===16)
        {
        msgtype="Downlink request";
        return (
          <div>
            <Typography variant="h5" gutterBottom>MessageType(5) : Downlink request</Typography>
            {batteryType(bin)}
          </div>
        );
        }
        break;
      case 6 : 
        if(bin.length===16)
        {
        msgtype="Downlink ACK";
        return (
          <div>
            <Typography variant="h5" gutterBottom>MessageType(6) : Downlink ACK</Typography>
            {batteryType(bin)}
          </div>
        );
        }
        break;
      case 7 : 
        if(bin.length===16)
        {
        msgtype="Data integrity status message";
        return (
          <div>
            <Typography variant="h5" gutterBottom>MessageType(7) : Data integrity status message</Typography>
            {batteryType(bin)}
            {tempType(bin)}
          </div>
        );
        }
        break;
      case 8 : 
        if(bin.length===16)
        {
        msgtype="Transit status message";
        return (
          <div>
            <Typography variant="h5" gutterBottom>MessageType(8) : Transit status message</Typography>
            {batteryType(bin)}
            {tempType(bin)}
            {timestampType(bin)}
          </div>
        );
        }
        break;
      default:
        break;
    }
    return null;
  }
  else if(type==='Uplink : AP message')
  {
    let aptype = 'error';
    let type00=bin.slice(7,8);
    let type01=bin.slice(6,7);
    let type60=bin.slice(55,56);
    let type61=bin.slice(54,55);

    if(type00==='0' && type60==='0') aptype="Normal Frame";
    else if(type00==='0' && type60==='1') aptype="Keep Alive";
    else if(type00==='1' && type60==='0') aptype="Reserved";
    else if(type00==='1' && type60==='1') aptype="Reserved";

    let rMac1 = parseInt(hexdata.slice(1,2),16)-parseInt(type01)*2-parseInt(type00);
    let rMac2 = parseInt(hexdata.slice(13,14),16)-parseInt(type61)*2-parseInt(type60);

    let Mac1 = hexdata.slice(0,1)+rMac1.toString(16)+hexdata.slice(2,12);
    let Mac2 = hexdata.slice(12,13)+rMac2.toString(16)+hexdata.slice(14,24);

    return (
      <div>
        <Typography variant="h5" gutterBottom>Mac1 : {Mac1}</Typography>
        <Typography variant="h5" gutterBottom>Mac2 : {Mac2}</Typography>
      </div>
    );
  }
  else if(type==='downlink')
  {
    msgtype=parseInt(bin.toString().slice(0,4),2);
    let messageid = bin.slice(0,4);
    let reserve= bin.slice(4,8);
    let alivestaticstatetimer = bin.slice(8,16);
    let downlinkfrequency=bin.slice(16,24);
    let speed = bin.slice(24,28);
    let broadcastcap = bin.slice(28,32);
    let movingtostatictimer = bin.slice(32,36);
    let distance = bin.slice(36,40);
    let stopdetectionwindow=bin.slice(40,42);
    let stopdetectionthresholdalive=bin.slice(42,44);
    let alivemovingstatetimer=bin.slice(44,48);
    let triggersoftwarefunction=bin.slice(48,56);
    let downlinkprotocolversion=bin.slice(56,60);
    let reserve2=bin.slice(60,64);

    let stopdetectionwindowmsg='error';
    if(stopdetectionwindow===0) stopdetectionwindowmsg="lower";
    else if(stopdetectionwindow===1) stopdetectionwindowmsg="normal";
    else if(stopdetectionwindow===10) stopdetectionwindowmsg="higher";
    else if(stopdetectionwindow===11) stopdetectionwindowmsg="highest";

    let stopdetectionthresholdalivemsg='error';
    if(stopdetectionthresholdalive===0) stopdetectionthresholdalivemsg="lower";
    else if(stopdetectionthresholdalive===1) stopdetectionthresholdalivemsg="normal";
    else if(stopdetectionthresholdalive===10) stopdetectionthresholdalivemsg="higher";
    else if(stopdetectionthresholdalive===11) stopdetectionthresholdalivemsg="highest";

    return (
    <div>
      <Typography variant="h5" gutterBottom>MessageID : {parseInt(messageid,2)}</Typography>
      <Typography variant="h5" gutterBottom>Alive static state timer : {parseInt(alivestaticstatetimer,2)} hr</Typography>
      <Typography variant="h5" gutterBottom>Downlink frequency : {parseInt(downlinkfrequency,2)} day</Typography>
      <Typography variant="h5" gutterBottom>Speed : {parseInt(speed,2)*5} km/h</Typography>
      <Typography variant="h5" gutterBottom>Broadcastcap : {(parseInt(broadcastcap,2)-1)*3} times/day</Typography>
      <Typography variant="h5" gutterBottom>Movingtostatictimer : {parseInt(movingtostatictimer,2)*0.5} hr</Typography>
      <Typography variant="h5" gutterBottom>Distance : {parseInt(distance,2)*0.5} km</Typography>
      <Typography variant="h5" gutterBottom>Stop detection window : {stopdetectionwindowmsg}</Typography>
      <Typography variant="h5" gutterBottom>Stop detection threshold : {stopdetectionthresholdalivemsg}</Typography>
      <Typography variant="h5" gutterBottom>Trigger software function : {parseInt(triggersoftwarefunction,2)}</Typography>
      <Typography variant="h5" gutterBottom>downlink protocol version : {parseInt(downlinkprotocolversion,2)}</Typography>
    </div>
    );
  }
  return null;
}
export default function Analyzer({props}) {
  const classes = useStyles();
  const [data, setData] = useState('')
  const [iserror, setError] = useState(true);

  const handleInputText = (text) =>{
    if(text.length > 24 || text.length%2===1 || text.length===0 ) setError(true);
    else {
      setError(false);
      setData(text);
    }
  }

  return (
    <div className={classes.root}>
    <Typography variant="h4" gutterBottom>Please input the uplink or downlink hex data</Typography>
    <TextField fullWidth 
        margin="normal"
        id="outlined-basic" 
        label="Outlined" 
        variant="outlined" 
        onChange={ event => handleInputText(event.target.value) }
    />
    {
      iserror
      ? <Typography variant="h5" gutterBottom>Please input proper uplink and downlink data</Typography>
      : <div>
          <Typography variant="h5" gutterBottom>HexData : {data}</Typography>
          <Typography variant="h5" gutterBottom>Input data size : {data.length}</Typography>
          {hex2bin(data)}
          {packetType(bin)}
          {messageType(bin,type)}
        </div>
    }
    </div>
  );
}