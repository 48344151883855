import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

//2020-08-24
import SettingComponent from './components/settingcomponent';

const useStyles = makeStyles((theme) => ({
  root: {
    //width: '100%',
    padding: theme.spacing(4),
    //backgroundColor: theme.palette.background.paper,
  }
}));

export default function Setting() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SettingComponent />
    </div>
  );
}