import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography
} from '@material-ui/core';
//2020-08-17
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Base64 = require('js-base64').Base64;

const schema = {
  username: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/login.jpeg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.black,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.black
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const Login = props => {
  const { history } = props;
  const { state } = props;
  const [error,setError] = useState(false);
  const [errmsg,setErrormsg] = useState('error msg');
  let timzone = "Asia/Tokyo";
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setError(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };
  const fetchALAPLoginAPI=async()=>{
    let id = formState.values.username;
    let pw = formState.values.password;
    console.log(id+" : "+pw);
    return fetch('https://emulator.tracker.alap-iot.net/auth/token',
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Basic '+Base64.encode(id+':'+pw)
        })
    })
    .then(res => {
        if(res.status==200)
        {
            return res.json();
        }
        else
        {
            console.log('Error');
            setError(true);
            setErrormsg('response error not 200');
        }
    })
    .then(res => {            
        if(typeof(res) !== "undefined" )
        {
            console.log('Token : '+res.accessToken);
            console.log('APP_TRACKER_ISLOGIN : '+String(new Date().getTime()));

            fetchUserInfo(res.accessToken);  
            //fetchGroups(res.accessToken);
        }
        else
        {
            console.log('Error');
            setError(true);
            setErrormsg('group api server has an error');
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg(error);
    });
  }
  const fetchUserInfo=async(auth)=>{
    console.log('Auth : ' + auth.toString());
    return fetch('https://emulator.tracker.alap-iot.net/user-info',
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+auth.toString()
        })
    })
    .then(res => {
        if(res.status==200)
        {
            console.log('Success');
            return res.json();
        }
        else
        {
            console.log('Status Error : '+res.status);
            setError(true);
            setErrormsg('response error not 200');
        }
    })
    .then(res => {            
        if(typeof(res) !== "undefined" && typeof(res.name) !== 'undefined')
        {
            console.log(res.name);
            fetchGroups(true,auth,res.name)
            //props.onChangeLoginState(true,auth,res.name);  
        }
        else
        {
            console.log('undefined Error');
            setError(true);
            setErrormsg('group api has error');
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg('fetch error');
    });
  }
  const fetchGroups=async(status,auth,name)=>{
    console.log('Auth : ' + auth.toString());
    return fetch('https://emulator.tracker.alap-iot.net/groups?subGroup=true',
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+auth.toString()
        })
    })
    .then(res => {
        if(res.status==200)
        {
            console.log('Success');
            return res.json();
        }
        else
        {
            console.log('Status Error : '+res.status);
            setError(true);
            setErrormsg('response error not 200');
        }
    })
    .then(res => {            
        if(typeof(res) !== "undefined" && typeof(res.groups) !== 'undefined')
        {
            console.log(res.groups);
            localStorage.setItem('@alaptoken', auth);
            props.onChangeLoginState(status,auth,res.groups,name);  
        }
        else
        {
            console.log('undefined Error');
            setError(true);
            setErrormsg('group api has error');
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg('fetch error');
    });
  }
  const handleSignIn = event => {
    event.preventDefault();
    //history.push('/');
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleBTNClick = async()=>{
    setOpen(!open);
    await fetchALAPLoginAPI();
    console.log('fetch');
  }

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignIn}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                </Typography>
                <img
                  alt="Logo"
                  src="/images/logos/alap--blue.png"
                  //src="/images/logos/alap--white.png"
                  width="135"
                  height="25"
                />
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Login
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  Login in with Username and Password
                </Typography>
                <Grid
                  className={classes.socialButtons}
                  container
                  spacing={2}
                >
                </Grid>
                <TextField
                  className={classes.textField}
                  error={hasError('username')}
                  fullWidth
                  helperText={
                    hasError('username') ? formState.errors.username[0] : null
                  }
                  label="Username"
                  name="username"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.username || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleBTNClick}
                >
                  Login Now
                </Button>
                <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar open={error} autoHideDuration={3000} onClose={handleClose}>
                  <MuiAlert onClose={handleClose} severity="error">
                    Login Failed !
                  </MuiAlert>
                </Snackbar>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don't have an account?{' '}Please contact to alpsalpine
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object
};

export default Login;
