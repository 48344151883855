import React,{useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import LockOpenIcon from '@material-ui/icons/LockOpen';

//ALAP
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import MapIcon from '@material-ui/icons/Map';
import LoopIcon from '@material-ui/icons/Loop';

import { Profile, SidebarNav, UpgradePlan,DevelopInfo } from './components';

//2020-08-21
import {SampleConsumer} from "./../../../../LangContext";

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, username, group, className, ...rest } = props;
  const classes = useStyles();
  const [lang,setLanguage] = React.useState("en");

  useEffect( ()=>{
    
  },[]);

  const pages = [
    {
      title: 'home',
      href: '/home',
      icon: <HomeIcon />
    },
    {
      title: 'map',
      href: '/map',
      icon: <MapIcon />
    },
    {
      title: 'idsearch',
      href: '/search',
      icon: <SearchIcon />
    },
    {
      title: 'packet',
      href: '/packet',
      icon: <LoopIcon />
    },
    {
      title: 'settings',
      href: '/setting',
      icon: <SettingsIcon />
    }
/*    
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: 'Users',
      href: '/users',
      icon: <PeopleIcon />
    },
    {
      title: 'Products',
      href: '/products',
      icon: <ShoppingBasketIcon />
    },
    {
      title: 'Authentication',
      href: '/sign-in',
      icon: <LockOpenIcon />
    },
    {
      title: 'Typography',
      href: '/typography',
      icon: <TextFieldsIcon />
    },
    {
      title: 'Icons',
      href: '/icons',
      icon: <ImageIcon />
    },
    {
      title: 'Account',
      href: '/account',
      icon: <AccountBoxIcon />
    },
    {
      title: 'Settings',
      href: '/settings',
      icon: <SettingsIcon />
    }
*/
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <SampleConsumer>
      {
        (sample) => setLanguage(sample.state.value) 
      }
      </SampleConsumer>
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile group={group} username={username}/>
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
          lang={lang}
        />
        {/*<UpgradePlan />*/}
        {/*<DevelopInfo />*/}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;