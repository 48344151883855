import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import { Sidebar, Topbar, Footer,Login } from './components';

//2020-07-21
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//2020-08-25
import i18n from 'i18n-js';
const langjson = require('./../../language.json');

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  }
}));

const Main = props => {
  const { children } = props;
  const [ islogin, setLogin] = useState(false);
  const [ token, setToken] = useState("");
  const [ lang, setLanguage] = useState(localStorage.getItem("@alaplang") === "null" ? "en" : localStorage.getItem("@alaplang") );
  const [ group, setGroup] = useState([]);
  const [ username, setUsername] = useState("");

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    i18n.translations = langjson;
    i18n.locale = lang;
    i18n.fallbacks = true;
    console.log("locale : "+lang);
  },[]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };
  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  const handleLoginState =(state,token,group,name) =>{
    setLogin(state);
    setToken(token);
    setGroup(group);
    setUsername(name);
    //console.log("localStorage : "+localStorage.getItem('@alaptoken'));
    console.log("username : "+name);
    if(state) setOpen(true);
  }
  
  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      {
        !islogin 
        ? <div>
          <Login onChangeLoginState={handleLoginState}/>
          <main className={classes.content}>
            <Footer />
          </main>
          </div>
        : <div>
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <MuiAlert onClose={handleClose} severity="success">
              Login success !
            </MuiAlert>
          </Snackbar>
          <Topbar onSidebarOpen={handleSidebarOpen} />
          <Sidebar
            group={group}
            username={username}
            onClose={handleSidebarClose}
            open={shouldOpenSidebar}
            variant={isDesktop ? 'persistent' : 'temporary'}
          />
          <main className={classes.content}>
            {children}
            <Footer />
          </main>
        </div>
      }
      
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
