import React from 'react';
import { makeStyles } from '@material-ui/styles';
import SimpleTable from './components/ALAPtable';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  }
}));

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SimpleTable />
    </div>
  );
};

export default Home;
