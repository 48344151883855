import React,{useState, useEffect} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';
import AutorenewIcon from '@material-ui/icons/Autorenew';

//2020-08-03
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import MapComponent from './ALAPdevicetypemapcomponent';

//2020-08-24
import i18n from "i18n-js";
import {SampleConsumer} from "./../../../LangContext";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const Base64 = require('js-base64').Base64;

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function DeviceType() {
  const classes = useStyles();
  const [groups, setGroups] = useState([]);
  const [devicetypes, setDevicetype] = useState([]);
  const [devices, setDevice] = useState([]);
  const [error,setError] = useState(false);
  const [errmsg,setErrormsg] = useState('error msg');
  const [auth,setAuth] = useState( localStorage.getItem('@alaptoken') || ''); 
  const [locations, setLocations] = useState([]);
  const [lang, setLanguage] = useState("en");
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const res = fetchGroups();
  },[]);

  const fetchToken=async()=>{
    let id = 'alaptest';
    let pw = 'alapiotpro2020test';
    console.log(id+" : "+pw);
    return fetch('https://emulator.tracker.alap-iot.net/auth/token',
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Basic '+Base64.encode(id+':'+pw)
        })
    })
    .then(res => {
        if(res.status==200)
        {
            console.log('State200');
            return res.json();
        }
        else
        {
            console.log('Error');
            setError(true);
            setErrormsg('response error not 200');
        }
    })
    .then(res => {            
        if(typeof(res) !== "undefined" )
        {
            console.log('Token : '+res.accessToken);
            console.log('APP_TRACKER_ISLOGIN : '+String(new Date().getTime()));

            setAuth(res.accessToken); 
            fetchGroups(res.accessToken); 
        }
        else
        {
            console.log('Error');
            setError(true);
            setErrormsg('group api server has an error');
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg(error);
    });
  }
  const fetchGroups=async()=>{
    console.log('Auth : ' + auth.toString());
    return fetch('https://emulator.tracker.alap-iot.net/groups?subGroup=true',
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+auth.toString()
        })
    })
    .then(res => {
        if(res.status==200)
        {
            console.log('Success');
            return res.json();
        }
        else
        {
            console.log('Status Error : '+res.status);
            setError(true);
            setErrormsg('response error not 200');
        }
    })
    .then(res => {            
        if(typeof(res) !== "undefined" && typeof(res.groups) !== 'undefined')
        {
            console.log(res.groups);
            setGroups(res.groups);
            //res.groups.map((data,index)=>
            //  fetchDeviceType(data.id)              
            //);
        }
        else
        {
            console.log('undefined Error');
            setError(true);
            setErrormsg('group api has error');
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg('fetch error');
    });
  }
  const fetchDeviceType=async(groupid)=>{
    console.log('Groupdid : '+'https://emulator.tracker.alap-iot.net/device-types?groupId='+groupid);
    return fetch('https://emulator.tracker.alap-iot.net/device-types?groupId='+groupid,
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+auth.toString()
        })
    })
    .then(res => {
        if(res.status==200)
        {
          console.log('devicetype ok');
          return res.json();
        }
        else
        {
          setError(true);
          setErrormsg('response error not 200');
        }
    })
    .then(res => {
        if(typeof(res) !== "undefined" && typeof(res["device-types"]) !== "undefined" )
        {
          console.log(res["device-types"]);
          setDevicetype(res["device-types"]);
        }
        else
        {
          setError(true);
          setErrormsg('could not get the devicetypes');
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg('fetch error');
    });
  }
  const fetchDevice=async(devicetypeid)=>{
    return fetch('https://emulator.tracker.alap-iot.net/devices?deviceTypeId='+devicetypeid,
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+auth.toString()
        })
    })
    .then(res => {
        if(res.status==200)
        {
          console.log('device ok');
          return res.json();
        }
        else
        {
          setError(true);
          setErrormsg('response error not 200');
        }
    })
    .then(res => {
        if(typeof(res) !== "undefined" && typeof(res.devices) !== "undefined" )
        {
            console.log(res.devices);
            let arr = [];
            res.devices.map((data,index)=>arr.push(data.id))
            setDevice(arr);
            fetchDeviceLocations(arr);
        }
        else
        {
          setError(true);
          setErrormsg('could not get the devices');
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg('fetch error');
    });
  }
  const fetchDeviceLocations= async(arr)=>{
    return fetch('https://emulator.tracker.alap-iot.net/devices/locations/latest',
    {
        method: 'POST',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+auth.toString()
        }),
        body: JSON.stringify({
            deviceId: arr
        })
    })
    .then(res => {
        if(res.status==200)
        {
          console.log('device ok');
          return res.json();
        }
        else
        {
          setError(true);
          setErrormsg('response error not 200');
        }
    })
    .then(res => {
        if(typeof(res) !== "undefined" && typeof(res.location) !== "undefined" )
        {
            console.log(res.location);
            setLocations(res.location);
            setOpen(false);
        }
        else
        {
          setError(true);
          setErrormsg('could not get the devices');
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg('fetch error');
    });
  }
  const getTableData=()=>{
    //setGroups([]);
    //setDevicetype([]);
    setDevice([]);
    setLocations([]);
    //const res = fetchGroups();
  }
  const handleClickGroup=(event)=>{
    setDevicetype([]);
    let groupid = event.target.value;
    console.log(groupid);
    if(groupid.length === 24)
    {
        fetchDeviceType(groupid);
    }
  }
  const handleClickDeviceType=(event)=>{
    let devicetypeid = event.target.value;
    console.log(devicetypeid);
    if(devicetypeid.length === 24)
    {
        fetchDevice(devicetypeid);
        setOpen(true);
    }
  }
  return (
    <Paper>
      <SampleConsumer>
      {
        (sample) => setLanguage(sample.state.value)
      }
      </SampleConsumer>
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        startIcon={<AutorenewIcon />}
        onClick={()=>getTableData()}
      >
        {i18n.t("reset")}
      </Button><br/>
      <FormControl className={classes.formControl} error>
          <InputLabel htmlFor="name-native-error">{i18n.t("group")}</InputLabel>
          <NativeSelect onChange={handleClickGroup}>
          <option aria-label="None" value="" />
          {
              groups.map((group,index)=><option key={index} value={group.id}>{group.name}</option>)
          }
          </NativeSelect>
      </FormControl>
      <FormControl className={classes.formControl} error>
          <InputLabel htmlFor="name-native-error">{i18n.t("devicetype")}</InputLabel>
          <NativeSelect onChange={handleClickDeviceType}>
          <option aria-label="None" value="" />
          {
              devicetypes.map((devicetype,index)=><option key={index} value={devicetype.id}>{devicetype.name}</option>)
          }
          </NativeSelect>
      </FormControl><br/><br/>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose} >
        <CircularProgress color="inherit" />
      </Backdrop>
      {
          locations.length>0 
          ? <MapComponent lat={locations[0].lat} lng={locations[0].lng} locations={locations}/>
          : null
      }
    </Paper>
  );
}