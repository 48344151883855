import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import Analyzer from './components/analyzer';
import Setting from './components/setting';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    //maxWidth: 500,
    padding: theme.spacing(4),
    //backgroundColor: theme.palette.background.paper,
  },
  input:{
    padding: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


export default function Packet() {
  const classes = useStyles();
  const [type,setType] = useState('analyzer');
  
  return (
    <div className={classes.root}>
      <Paper>
      <Grid container spacing={3} className={classes.input}>
        <Grid item xs={6}>
          {
            type==='analyzer'
            ? <Button variant="contained" color="primary" onClick={()=>setType('analyzer')}>Packet Analyzer</Button>
            : <Button variant="contained" color="default" onClick={()=>setType('analyzer')}>Packet Analyzer</Button>
          }
        </Grid>
        <Grid item xs={6}>
          {
            type==='setting'
            ? <Button variant="contained" color="primary" onClick={()=>setType('setting')}>Downlink Settings</Button>
            : <Button variant="contained" color="default" onClick={()=>setType('setting')}>Downlink Settings</Button>
          }
        </Grid>
      </Grid>
      {
        type === 'analyzer' 
        ? <Analyzer />
        : <Setting />
      }
      </Paper>
    {
      /*
      iserror
      ? <Typography>Input data integrity : false</Typography>
      : <div>
          <Typography>Input data integrity : true</Typography>
          <Typography>Input data size : {data.length}</Typography>
          {hex2bin(data)}
          {packetType(bin)}
          {messageType(bin,type)}
        </div>
        */
    }
    </div>
  );
}