import React,{useState, useEffect} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

//2020-08-06
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

//2020-08-03
import DevicesIcon from '@material-ui/icons/Devices';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MessageIcon from '@material-ui/icons/Message';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';

//2020-08-24
import i18n from "i18n-js";
import MapComponent from './ALAPmapcomponent';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const TOKEN = "76f34f0bd73d3f";


export default function StatusComponent(props) {
  const classes = useStyles();

  const deviceid = props.deviceid;
  const statuses = props.status;
  const locations = props.location;
  const [open, setOpen] = React.useState(false);
  const [error,setError] = React.useState(false);
  const [errmsg,setErrormsg] = React.useState('error msg');
  const [address, setAddress] = React.useState("");
  const lang = props.lang;


  const handleClick = (lat,lng) => {
    setAddress("");
    if(!open)fetchGeocodingAPI(lat,lng);
    setOpen(!open);
  };


  const convertTime=(data,option)=>{
    let date = new Date(parseInt(data));
    let res = '';
    if(option === 'DATE') res = date.toLocaleDateString();
    else if(option === 'DATETIME') res = date.toLocaleString();
    else res = date.toLocaleDateString();
    return res;
  }
  const convertBatt = (data)=>{
    if(data === 0 ) return "Limit";
    else if(data === 1 ) return "Average";
    else if(data === 2 ) return "Good";
    else if(data === 3 ) return "Excellent";
    else return "Error";
  }
  const convertTimezone=()=>{
    let date = new Date().getTimezoneOffset();
    if(date < 0){
      date=(-1)*date/60;
      date = `GMT+${date}`
    }
    else {
      date=date/60;
      date = `GMT-${date}`
    }
    return date;
  }
  const fetchGeocodingAPI=async(lat,lng)=>{
    return fetch(`https://nominatim.openstreetmap.org/reverse?format=json&accept-language=${lang}&lat=${lat}&lon=${lng}&zoom=18&addressdetails=1`)
    .then(res => {
        if(res.status==200)
        {
          return res.json();
        }
        else
        {
          console.log('Error');
          setError(true);
          setErrormsg('response error not 200');
        }
    })
    .then(res => {
        if(typeof(res) !== "undefined" && typeof(res.display_name) !== "undefined" )
        {
            if(res.display_name !== null )
            {
                console.log(res.display_name);
                setAddress(res.display_name)
            }
            else
            {
              console.log('Error');
              setError(true);
              setErrormsg('response status NOK');
            }   
        }
        else
        {
          console.log('Error');
          setError(true);
          setErrormsg('response error getting the data');
        }
    })
    .catch((error) => {
      console.log('Error');
      setError(true);
      setErrormsg(error);
    })
  }  
  return (
      <Paper>
      {
        typeof(statuses) !== "undefined" && statuses.length >0 && statuses.map((data,index)=>
        <List key={index} component="div" disablePadding>
        <ListItem  className={classes.nested}>
            <ListItemIcon>
            <DevicesIcon />
            </ListItemIcon>
            <ListItemText primary={`${i18n.t("device")} : ${deviceid.toUpperCase()}`} />
        </ListItem>
        <ListItem  className={classes.nested}>
            <ListItemIcon>
            <MessageIcon />
            </ListItemIcon>
            <ListItemText primary={`${i18n.t("msgtype")} : ${data.type}`} />
        </ListItem>
        <ListItem  className={classes.nested}>
            <ListItemIcon>
            <FormatListNumberedIcon />
            </ListItemIcon>
            <ListItemText primary={`${i18n.t("seqnumber")} : ${data.seqNumber}`} />
        </ListItem>
        <ListItem  className={classes.nested}>
            <ListItemIcon>
            <QueryBuilderIcon />
            </ListItemIcon>
            <ListItemText primary={`${i18n.t("lastcom")} : ${convertTime(data.time*1000,'DATETIME')}`} />
        </ListItem>
        <ListItem  className={classes.nested}>
            <ListItemIcon>
            <SignalCellularAltIcon />
            </ListItemIcon>
            <ListItemText primary={`${i18n.t("lqi")} : ${data.lqi}`} />
        </ListItem>
        <ListItem  className={classes.nested}>
            <ListItemIcon>
            <BatteryFullIcon />
            </ListItemIcon>
            <ListItemText primary={`${i18n.t("battery")} : ${convertBatt(data.battery)}`} />
        </ListItem>
        <ListItem  className={classes.nested}>
            <ListItemIcon>
            <WbSunnyIcon />
            </ListItemIcon>
            <ListItemText primary={`${i18n.t("temperature")} : ${data.temperature}`} />
        </ListItem>
        <ListItem  className={classes.nested}>
            <ListItemIcon>
            <TimelapseIcon />
            </ListItemIcon>
            <ListItemText primary={`${i18n.t("timestamp")} : ${data.timestamp*6} min elapsed`} />
        </ListItem>
        <ListItem  className={classes.nested}>
            <ListItemIcon>
            <BookmarkBorderIcon />
            </ListItemIcon>
            <ListItemText primary={`${i18n.t("payload")} : ${data.payload}`} />
        </ListItem>
        {
          typeof(locations) !== "undefined" && locations.length >0 &&
          locations.map((data,index)=>
          <List key={index} component="div" disablePadding>
            <ListItem button className={classes.nested}>
                <ListItemIcon>
                <FormatListNumberedIcon />
                </ListItemIcon>
                <ListItemText primary={`${i18n.t("seqnumber")} : ${data.seqNumber}`} />
            </ListItem>
            <ListItem button className={classes.nested}>
                <ListItemIcon>
                <QueryBuilderIcon />
                </ListItemIcon>
                <ListItemText primary={`${i18n.t("lastcom")} : ${convertTime(data.time*1000,'DATETIME')}`} />
            </ListItem>
            <ListItem button className={classes.nested}>
                <ListItemIcon>
                <SignalCellularAltIcon />
                </ListItemIcon>
                <ListItemText primary={`${i18n.t("lqi")} : ${data.lqi}`} />
            </ListItem>
            <ListItem button className={classes.nested} onClick={()=>handleClick(data.lat,data.lng)}>
                <ListItemIcon>
                <LocationOnIcon />
                </ListItemIcon>
                <ListItemText primary={`${i18n.t("address")} : ${data.lat} , ${data.lng}`} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  {
                    address !== null
                    ? <ListItemText primary={`${i18n.t("address")} : ${address}`}  />
                    : <ListItemText primary={`${i18n.t("address")} : "no address"`}  />
                  }
                </ListItem>
              </List>
            </Collapse>
            </List>
            )
        }
        </List>
        )
      }
      {
        typeof(locations) !== "undefined" && locations.length >0
        ? <MapComponent deviceid={deviceid} lat={locations[0].lat} lng={locations[0].lng} locations={locations}/>
        : null
      }
      </Paper>
  );
}