import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import TranslateIcon from '@material-ui/icons/Translate';
import InputIcon from '@material-ui/icons/Input';
import Typography from '@material-ui/core/Typography';

//2020-08-21
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
//2020-08-25
import i18n from "i18n-js";
import {SampleProvider,SampleConsumer} from './../../../../LangContext';

//2020-08-25
const langjson=require('./../../../../language.json');

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor:"white"
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();
  const [lang,setLanguage] = useState(props.value);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelectBTN = (lang) => {
    //window.location.reload(false);
    setAnchorEl(null);
    setLanguage(lang);
    props.setValue(lang);
    console.log("language : "+lang);
    i18n.translations = langjson;
    i18n.fallbacks = true;
    if(lang!==null) {
      localStorage.setItem('@alaplang', lang);
      i18n.locale = lang;
    }
    else {
      localStorage.setItem('@alaplang', "en");
      i18n.locale = "en";
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
          <img
            alt="Logo"
            //src="/images/logos/locaTra.png"
            src="/images/logos/alap--white.png"
            width="135"
            height="26"
          />
        <div className={classes.flexGrow} />
        <Hidden>
          <IconButton color="inherit" onClick={handleClick}>
            <TranslateIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={()=>handleSelectBTN("en")} style={{background:'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'}}>
              <ListItemText disableTypography primary={<Typography type="body2" style={{ color: '#FFFFFF' }}>English</Typography>}/>
            </MenuItem>
            <MenuItem onClick={()=>handleSelectBTN("ja")} style={{background:'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'}}>
              <ListItemText disableTypography primary={<Typography type="body2" style={{ color: '#FFFFFF' }}>日本語</Typography>}/>
            </MenuItem>
            <MenuItem onClick={()=>handleSelectBTN("ko")} style={{background:'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'}}>
              <ListItemText disableTypography primary={<Typography type="body2" style={{ color: '#FFFFFF' }}>한국어</Typography>}/>
            </MenuItem>
            {/*
            <MenuItem onClick={()=>handleSelectBTN("es")}>
              <ListItemText primary="Español" />
            </MenuItem>
            <MenuItem onClick={()=>handleSelectBTN("pt")}>
              <ListItemText primary="Português" />
            </MenuItem>
            */}
          </Menu>
          <RouterLink to="/">
          <IconButton
            className={classes.signOutButton}
            color="inherit"
          >
            <InputIcon />
          </IconButton>
          </RouterLink>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

//export default Topbar;
// :: Consumer 를 사용하여 context 값을 전달해준 컨테이너 컴포넌트
const SendsContainer = (props) => (
  <SampleConsumer>
    {
      ({state, actions}) => (
        <Topbar 
          value={state.value}
          setValue={actions.setValue}
          onSidebarOpen={props.onSidebarOpen}
        />
      )
    }
  </SampleConsumer>
)
SendsContainer.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

// :: Sends 대신에 SendsContainer 를 내보내줌
export default SendsContainer;