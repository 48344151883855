import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import InfoIcon from '@material-ui/icons/Info';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import Paper from '@material-ui/core/Paper';

//2020-08-24
import i18n from "i18n-js";
import {SampleConsumer} from './../../../LangContext';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  }
}));

export default function SettingComponent() {
  const classes = useStyles();
  const [lang, setLanguage] = React.useState("en");

  React.useEffect(() => {
    
  },[]);
  
  const getLanguage = ()=>{
    let res = "English";
    const lang = localStorage.getItem("@alaplang");
    if(lang === "ja") res="日本語";
    else if(lang === "ko") res="한국어";
    return res;
  }
  const convertTimezone=()=>{
    let date = new Date().getTimezoneOffset();
    if(date < 0){
      date=(-1)*date/60;
      date = `GMT+${date}`
    }
    else {
      date=date/60;
      date = `GMT-${date}`
    }
    return date;
  }

  return (
    <Paper>
      <SampleConsumer>
      {
        (sample) => setLanguage(sample.state.value) 
      }
      </SampleConsumer>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {i18n.t("settings")}
          </ListSubheader>
        }
        className={classes.root}
      >
        <ListItem divider={true}>
          <ListItemIcon><InfoIcon color="primary"/></ListItemIcon>
          <ListItemText primary={i18n.t("version")} />
          <ListItemSecondaryAction><Typography>v1.0.1.9</Typography></ListItemSecondaryAction>
        </ListItem>
        <ListItem divider={true}>
          <ListItemIcon><AccessibilityIcon color="primary"/></ListItemIcon>
          <ListItemText primary={i18n.t("developer")} />
          <ListItemSecondaryAction><Typography>Alpsalpine iot dept</Typography></ListItemSecondaryAction>
        </ListItem>
        <ListItem divider={true}>
          <ListItemIcon><AlternateEmailIcon color="primary"/></ListItemIcon>
          <ListItemText primary={i18n.t("helpline")} />
          <ListItemSecondaryAction><Typography>iot.admin@alpsalpine.com</Typography></ListItemSecondaryAction>
        </ListItem>
        <ListItem divider={true}>
          <ListItemIcon><GTranslateIcon color="primary"/></ListItemIcon>
          <ListItemText primary={i18n.t("language")} />
          <ListItemSecondaryAction><Typography>{getLanguage()}</Typography></ListItemSecondaryAction>
        </ListItem>
        <ListItem divider={true}>
          <ListItemIcon><QueryBuilderIcon color="primary"/></ListItemIcon>
          <ListItemText primary={i18n.t("timezone")} />
          <ListItemSecondaryAction><Typography>{convertTimezone()}</Typography></ListItemSecondaryAction>
        </ListItem>
      </List>
    </Paper>
  );
}