import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';

//2020-08-24
import i18n from "i18n-js";
const langjson=require('./../../../language.json');

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function MaterialUIPickers(props) {
  // The first commit of Material-UI
  const classes = useStyles();
  const [startDate, setStartDate] = React.useState(new Date(new Date().getTime()-7*86400000));
  const [endDate, setEndDate] = React.useState(new Date());
  const lang = props.lang;

  React.useEffect(() => {
    i18n.translations = langjson;
    i18n.locale = lang;
    i18n.fallbacks = true;
  },[]);

  const handleStartDateChange = (date) => {
    console.log(date);
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    console.log(date);
    setEndDate(date);
  };
  const handleBTN = ()=>{
    props.onChangeDateState(startDate,endDate);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid>
        <Typography variant="h4">{i18n.t("trajectory")}</Typography>
      </Grid>
      <Grid>
        <KeyboardDatePicker
          variant="normal"
          format="MM/dd/yyyy"
          margin="normal"
          id="start-date-picker-inline"
          label={i18n.t("starttime")}
          value={startDate}
          onChange={handleStartDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <Grid>
        <KeyboardDatePicker
          margin="normal"
          id="end-date-picker-dialog"
          label={i18n.t("endtime")}
          format="MM/dd/yyyy"
          value={endDate}
          onChange={handleEndDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <Grid>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<SearchIcon />}
          onClick={()=>handleBTN()}
        >
          {i18n.t("btnselect")}
        </Button>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}