import React, { useState,useEffect } from "react";
import { Map, TileLayer, Popup,Polyline, Marker } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {Icon} from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const greenicon = new Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});
const redicon = new Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});
const blueicon = new Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25*2/3, 41*2/3],
  iconAnchor: [12*2/3, 41*2/3],
  popupAnchor: [1, -34],
  shadowSize: [41*2/3, 41*2/3]
});

const convertLocationType = (data) =>{
  let res = "NoData";
  if(data==66) return "ALAPWiFi";
  else if(data==6) return "AtlasWiFi";
  else if(data==2) return "AtlasNative";

  return res;
}


export default function MapComponent(props) {
  const locations = props.locations;
  const trajectory = props.trajectory;
  const zoom= 10;
  const osmAttribution ='© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
  const osmDefaultUrl ="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  const position = [props.lat, props.lng];
  const deviceid = props.deviceid;
  const arr = [];

  return (
    <Map center={position} zoom={zoom} style={{ width:"80vh",height: "80vh" }}>
      <TileLayer
        url={osmDefaultUrl}
        attribution={osmAttribution} 
      />
      {
        typeof(locations)!=="undefined" &&
        locations.map((data,index)=><Marker key={index} position={[data.lat,data.lng]}>
            <Popup>
              <span>
                [{convertLocationType(data.source)}] [{data.seqNumber}] [{deviceid}] [{new Date(data.time*1000).toLocaleDateString()}] <br />
                {(data.lat).toFixed(6)} , {(data.lng).toFixed(6)} 
              </span>
            </Popup>
          </Marker>  
        )
      }
      {
        typeof(trajectory)!=="undefined" &&
        trajectory.map((data,index)=>
        <Marker key={index} position={[data.lat,data.lng]} icon={ index === 0 ? redicon : index === trajectory.length-1 ? greenicon : blueicon}>
          <Popup>
            <span>
              [{convertLocationType(data.source)}] [{data.seqNumber}] [{deviceid}] [{new Date(data.time*1000).toLocaleDateString()}] <br />
              {(data.lat).toFixed(6)} , {(data.lng).toFixed(6)} 
            </span>
          </Popup>
        </Marker> 
        )
      }
      {
        typeof(trajectory)!=="undefined" &&
        trajectory.map((data)=>arr.push([data.lat,data.lng]))
      }
      { arr.length >0  ? <Polyline positions={arr} /> : null }
    </Map>
  );
}