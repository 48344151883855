import React, { useState,useEffect } from "react";
import { Map, TileLayer, Popup,Polyline, Marker } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

//2020-08-20
import MarkerClusterGroup from "react-leaflet-markercluster";
import 'react-leaflet-markercluster/dist/styles.min.css'; // sass
require('react-leaflet-markercluster/dist/styles.min.css'); // inside .js file

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
const convertLocationType = (data) =>{
  let res = "NoData";
  if(data==66) return "ALAPWiFi";
  else if(data==6) return "AtlasWiFi";
  else if(data==2) return "AtlasNative";

  return res;
}

export default function MapComponent(props) {
  const lat= props.lat;
  const lng= props.lng;
  const locations = props.locations;
  const zoom= 10;
  const osmAttribution ='© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
  const osmDefaultUrl ="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  const position = [lat, lng];

  return (
    <Map center={position} zoom={zoom} style={{ width:"120vh",height: "80vh" }}>
      <TileLayer
        url={osmDefaultUrl}
        attribution={osmAttribution} 
      />
      <MarkerClusterGroup>
      {
        locations.map((data,index)=>
        <Marker key={index} position={[data.lat,data.lng]} >
            <Popup>
              <span>
                [{convertLocationType(data.source)}] [{data.seqNumber}] [{data.id}] [{new Date(data.time*1000).toLocaleDateString()}] <br />
                {(data.lat).toFixed(6)} , {(data.lng).toFixed(6)} 
              </span>
            </Popup>
          </Marker>  
        )
      }
      </MarkerClusterGroup>
    </Map>
  );
}