import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import MapComponent from './ALAPmapcomponent';
import MaterialUIPickers from './ALAPdatepickercomponent';
import NestedList from './ALAPstatuscomponent';

//2020-07-31
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';

//2020-08-24
import i18n from "i18n-js";

const useStyles = makeStyles(theme=>({
  table: {
    minWidth: 650,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function DetailTable(props) {
  const classes = useStyles();
  const fromtime = useState('');
  const totime = useState('');
  const [error,setError] = useState(false);
  const [errmsg,setErrormsg] = useState('error msg');
  const [locations, setLocations] = useState([]);
  const [trajectory, setTrajectory] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [clickinfobtn, setClickedInfoBTN] = useState(0);
  const [clicktrabtn, setClickedTraBTN] = useState(0);
  const [start,setStartTime] = useState("");
  const [end,setEndTime] = useState("");

  const auth = props.auth;
  const lang = props.lang;
  const deviceid = props.deviceid;
  const handleClose = props.handleClose;
  
  useEffect(() => {
    // Update the groups and devicetype data
    console.log("useEffect...");
    fetchDevicesStatus();
    fetchDevicesLocation();
  },[deviceid]);

  const fetchDevicesStatus=async()=>{
    return fetch('https://emulator.tracker.alap-iot.net/devices/statuses/'+deviceid,
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+auth.toString()
        })
    })
    .then(res => {
        if(res.status==200)
        {
            console.log('status ok : '+deviceid);
            return res.json();
        }
        else
        {
          setError(true);
          setErrormsg('response error not 200');
        }
    })
    .then(res => {
        if(typeof(res) !== "undefined" && typeof(res.status) !== "undefined" )
        {
          console.log(res.status);
          setStatuses(res.status);
          handleClose();
        }
        else
        {
          setError(true);
          setErrormsg('device api server has an error');
          handleClose(false);
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg('device api server has an error');
    });
  }
  const fetchDevicesLocation=async(from,to)=>{
    let url = '';
    if(typeof(from)!== "undefined" && typeof(to)!=="undefined")
    {
      url = `https://emulator.tracker.alap-iot.net/devices/locations/${deviceid}?limit=100&from=${from}&to=${to}`;
    }  
    else
    {
      url = 'https://emulator.tracker.alap-iot.net/devices/locations/'+deviceid;
    }
    console.log(url);
    return fetch(url,
    {
        method: 'GET',
        headers: new Headers({
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+auth.toString()
        })
    })
    .then(res => {
        if(res.status==200)
        {
            console.log('location ok : '+deviceid);
            return res.json();
        }
        else
        {
          setError(true);
          setErrormsg('response error not 200');
        }
    })
    .then(res => {
        if(typeof(res) !== "undefined" && typeof(res.location) !== "undefined" )
        {
          console.log(res.location);
          if(typeof(from)!== "undefined" && typeof(to)!=="undefined")
          {
            setTrajectory(res.location);
          }
          else
          {
            setLocations(res.location);
          }
        }
        else
        {
          setError(true);
          setErrormsg('device api server has an error');
        }
    })
    .catch((error) => {
        console.error(error);
        setError(true);
        setErrormsg('device api server has an error');
    });
  }
  const handleTrajectory=()=>{
    setClickedTraBTN(1);
  }
  const handleStatus=async()=>{
    setClickedInfoBTN(1);
    const status = await fetchDevicesStatus();
    const loc = await fetchDevicesLocation();
  }
  const handleTime =(start,end) =>{
    console.log(start);
    console.log(end);
    let starttime=Math.round(start.getTime()/1000);
    let endtime=Math.round(end.getTime()/1000);
    setStartTime(starttime);
    setEndTime(endtime);
    const loc = fetchDevicesLocation(starttime,endtime);
  }
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{i18n.t("type")}</TableCell>
            <TableCell>{i18n.t("info")}</TableCell>
            <TableCell>{i18n.t("map")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    startIcon={<InfoIcon />}
                    onClick={()=>handleStatus()}
                >
                    {i18n.t("info")}
                </Button>
            </TableCell>
            <TableCell>
            {
                locations.length >0 && statuses.length >0
                ? <NestedList lang={lang} deviceid={deviceid} status={statuses} location={locations}/>
                : null
            }
            </TableCell>
            <TableCell>
            {
              locations.length >0
              ? <MapComponent deviceid={deviceid} lang={lang} lat={locations[0].lat} lng={locations[0].lng} locations={locations}/>
              : null
            }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  startIcon={<SwapCallsIcon />}
                  onClick={()=>handleTrajectory()}
                >
                {i18n.t("trajectory")}
                </Button>
            </TableCell>
            <TableCell>
            {
              clicktrabtn >0
              ? <MaterialUIPickers lang={lang} onChangeDateState={handleTime} /> 
              : null
            }
            </TableCell>
            <TableCell>
            {
              trajectory.length >0
              ? <MapComponent deviceid={deviceid} lang={lang} lat={trajectory[0].lat} lng={trajectory[0].lng} trajectory={trajectory}/>
              : null
            }
            </TableCell>
        </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}